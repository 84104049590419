@import url("https://fonts.googleapis.com/css?family=Dancing+Script");
@import url("https://fonts.googleapis.com/css?family=Indie+Flower");
.logo-text {
    font-family: "Dancing Script", latin;
    font-size: 100px;
    white-space: nowrap;
}

body {
    background: rgb(179, 86, 0);
    background: linear-gradient(0deg, rgba(179, 86, 0, 1) 0%, rgba(255, 141, 0, 1) 30%);
    background-attachment: fixed;
    height: 100%;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button {
    margin-top: 5px;
    background: #00afff;
    width: 150px;
    border: 0px solid transparent;
    border-radius: 10px;
    font-size: 20px;
}

#game-buttons {
    float: right;
}

#complete-story-button {
    margin-right: 5px;
    background: #bff1ff;
    width: 175px;
}

.start-game-button {
    margin: 10px;
}

.end-round-button {
    float: left;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.logo-page {
    margin-left: auto;
    margin-right: auto;
    width: 700px;
    height: 100%;
    border-collapse: collapse;
    display: table;
}

.logo-page-content {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 25px;
}

.copyright-notice {
    display: table-footer-group;
    text-align: center;
    margin-top: 25px;
}

html,
body,
#root,
#content {
    height: 100%;
}

#lobby-content {
    word-wrap: anywhere;
}

#game-content {
    word-wrap: anywhere;
}

.please-wait-text {
    font-size: 25px;
    margin-top: 25px;
    text-align: center;
}

.gallery-header {
    font-family: "Dancing Script", latin;
    font-size: 30px;
    white-space: nowrap;
    margin-top: 10px;
}

#invite-friends {
    margin: 25px;
}

#invite-friends .button {
    width: inherit;
}

#timer {
    font-size: 35px;
    margin-top: 25px;
    text-align: center;
    font-weight: bold;
}

ul {
    padding-left: 0px;
    list-style-type: none;
}

.warning-text {
    color: red;
}

.setting {
    margin: 7px;
}

.setting-label {
    font-weight: bold;
    text-align: right;
}

.setting-input {
    margin-left: 10px;
    margin-bottom: 5px;
}

.setting-table {
    margin-left: auto;
    margin-right: auto;
}

.section-header {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 32px;
}

.bold-text {
    font-weight: bold;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.paper {
    height: 25px;
}

.paper-stack {
    white-space: nowrap;
}

.player-name {
    font-size: 25px;
}

.previous-round-stories {
    margin-top: 10px;
}

.message-preview {
    color: gray;
}

.help-about-links {
    margin-top: 25px;
}

.help-link {
    padding-right: 25px;
}

.not-centered-text {
    text-align: left;
}

.not-centered-text h2 {
    text-align: center;
}

#poop-emoji:hover {
    animation: poop-spin infinite 0.65s ease;
    display: inline-block;
}

@keyframes poop-spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.space-left {
    margin-left: 25px;
}

/* Lined paper CSS */
/* Styling specific to an index card */
.card {
    font-family: "Indie Flower", normal;
    background-color: white;
    background-position-y: 34px;
    width: 700px;
    padding: 0;
    margin-bottom: 20px;
    word-wrap: anywhere;
}

.card header {
    background: linear-gradient(white, white 97%, pink 99%, pink 100%);
}

.card-title {
    position: relative;
    left: 10px;
    font-size: 30px;
    font-weight: bold;
}

.card-text {
    background: repeating-linear-gradient(white, white 25px, #9198e5 26px, #9198e5 27px);
    position: relative;
    font-size: 22px;
    margin: 0 0px;
    line-height: 27px;
    text-align: left;
    padding-top: 5px;
    padding-left: 20px;
    min-height: 5em;
}

.shorten-card-text {
    min-height: auto;
}
